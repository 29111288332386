@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  font-family: 'Poppins';
}

body {
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: rgb(27, 27, 27);
}

.centered-card {
  max-width: 400px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 10px;
}


.container {
  width: 100%;
  min-height: 100%;
  background-color: transparent;
}


.footer {
  text-align: center;
  font-weight: 300;
  padding-bottom: 15px;
  font-family: 'Poppins';
  font-size: 0.8rem;
  background-color: rgb(0, 122, 255);
  color: rgb(245, 245, 245);
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background: rgb(245, 245, 245);
}

::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  background-clip: padding-box;
}