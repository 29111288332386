@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background-color: #f9f9f9;
}

.collection-detail-page {
    margin-top: 2rem;
    padding: 100px;
    text-align: center;
}

.collection-title {
    margin-bottom: 10px;
    font-weight: 600;
}

.anime-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Media Query for Smaller Screens */
@media (max-width: 1024px) {
    .anime-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .anime-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .anime-grid {
        grid-template-columns: 1fr;
    }
}

.anime-card {
    position: relative;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.anime-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.anime-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
}

.anime-title {
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
}

.remove-anime-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #cc0000;
}

.remove-anime-button:hover {
    color: #ff0000;
}

.error-message {
    color: red;
    margin: 20px 0;
}

.delete-collection-button,
.share-collection-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
    margin: 10px;
}

.delete-collection-button:hover,
.share-collection-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.delete-collection-button:active,
.share-collection-button:active {
    transform: translateY(0);
    background-color: #004494;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.share-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Adds depth */
    max-width: 500px;
    width: 100%;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in animation */
}

.share-modal h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.uuid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #f1f1f1;
    border-radius: 8px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); /* Inset shadow */
}

.uuid-text {
    margin-right: 10px;
    font-weight: 500;
}

.copy-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.copy-button:hover {
    background-color: #0056b3;
}

.copy-button:active {
    background-color: #004494;
}

.close-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 0.9rem;
    cursor: pointer;
    margin-top: 1rem;
    transition: background-color 0.3s ease;
}

.close-button:hover {
    background-color: #c0392b;
}

.close-button:active {
    background-color: #a93226;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

