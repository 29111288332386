@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
    font-family: 'Poppins';
}

.intro {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: clamp(9rem, 5.8310rem + 13.5211vw, 18rem);
    padding-bottom: 33.1rem;
    text-align: center;
    z-index: 1;
    background-image: url('../../static/background.jpg');
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: rgb(27, 27, 27, 0.4);
}

.search-box {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center; /* Center the search bar */
    width: 100%;
}

.search-input {
    width: 60%; /* Use a percentage to make it responsive */
    max-width: 410px; /* Set a maximum width for larger screens */
    padding: 13px;
    border: 3px solid rgb(0, 122, 255);
    border-radius: 25px 0 0 25px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
}

.search-input::placeholder {
    color: rgb(168, 168, 168);
}

.search-input:focus {
    border-color: rgb(0, 100, 210);
}

.search-button {
    background-color: rgb(0, 122, 255);
    border: 3px solid rgb(0, 122, 255);
    border-radius: 0 25px 25px 0;
    color: white;
    padding: 13px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}