@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

.search-results {
    margin-top: 4rem;
    margin-right: clamp(1rem, -5.6901rem + 28.5446vw, 20rem);
    margin-left: clamp(1rem, -5.6901rem + 28.5446vw, 20rem);
    border-radius: 2rem;
}

.searchResultsFor {
    padding-top: 2rem;
    text-align: left;
    margin-left: clamp(1rem, -0.0563rem + 4.5070vw, 4rem);
    font-size: 2rem;
}

/* Anime Card Grid */
.anime-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 0 auto;
    padding: 0;
}

/* Media Query for Responsive Layout */
@media (max-width: 1024px) {
    .anime-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .anime-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .anime-grid {
        grid-template-columns: 1fr;
    }
}

.anime-card {
    position: relative;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.anime-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.anime-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
}

.anime-title {
    margin: 10px 0;
    font-size: 1.2rem;
    font-weight: 700;
    color: #007bff;
    text-align: center;
}

.anime-info {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #555;
    line-height: 1.4;
    text-align: center;
}

.episode-info,
.year-info {
    text-align: center;
    display: block;
}
